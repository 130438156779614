<template>
  <div class="dashboard">
    <cumulative-income-graph/>
    <income-graph/>
    <cumulative-user-reg-graph/>
    <user-subscribe-graph/>
    <month-ARPU-graph/>
    <LTV-graph/>
    <active-user-amount-graph/>
    <churn-rate-graph/>
    <user-time-spent-graph/>
    <conversion-rates-graph/>
    <user-reg-increase-graph class="mb-4"/>
  </div>
</template>
<script>
import ConversionRatesGraph from '@/components/analytics/ConversionRatesGraph'
import UserTimeSpentGraph from '@/components/analytics/UserTimeSpentGraph'
import ChurnRateGraph from '@/components/analytics/ChurnRateGraph'
import ActiveUserAmountGraph from "@/components/analytics/ActiveUserAmountGraph"
import LTVGraph from "@/components/analytics/LTVGraph";
import MonthARPUGraph from "@/components/analytics/MonthARPUGraph";
import UserSubscribeGraph from "@/components/analytics/UserSubscribeGraph";
import CumulativeUserRegGraph from "@/components/analytics/CumulativeUserRegGraph";
import CumulativeIncomeGraph from "@/components/analytics/CumulativeIncomeGraph";
import IncomeGraph from "@/components/analytics/IncomeGraph";
import UserRegIncreaseGraph from "@/components/analytics/UserRegIncreaseGraph";

export default {
  name: "Dashboard",
  components:{
    ConversionRatesGraph,
    ChurnRateGraph,
    MonthARPUGraph,
    CumulativeIncomeGraph,
    IncomeGraph,
    UserRegIncreaseGraph,
    CumulativeUserRegGraph,
    UserSubscribeGraph,
    LTVGraph,
    ActiveUserAmountGraph,
    UserTimeSpentGraph
  },
  props: {
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.dashboard{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
</style>
