<template>
  <CCard class="card shadow-none">
    <CCardHeader class="c-card-header d-flex bg-dark position-relative">
      <h4>
        {{ $t('lk.analytics.conversionRates') }}
      </h4>
      <label
        class="mb-0 col-form-label-sm ml-auto"
        @click="isPercents = false">
        absolute
      </label>
      <CSwitch
        color="primary"
        shape="pill"
        :checked.sync="isPercents"
        class="mx-3 switch-custom"
        id="switch"
      />
      <label
        class="mb-0 col-form-label-sm"
        @click="isPercents = true">
        %
      </label>
    </CCardHeader>
    <CCardBody class="px-0 py-1">
      <div
        v-if="isLoading"
        class="loader"
      >
        <CSpinner
          color="primary"
        />
      </div>
      <div
        v-if="!isLoading && !responseData.length"
        class="loader"
      >
        <h3>
          {{ $t('general.noData') }}
        </h3>
      </div>
      <div
        class="position-relative"
      >
        <canvas ref="conversionRatesGraph" width="400" height="300"></canvas>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import {
  Chart,
  BarElement,
  CategoryScale,
  LinearScale,
  BarController,
  Tooltip
} from 'chart.js';
import axios from "axios";

Chart.register(
  BarElement,
  CategoryScale,
  LinearScale,
  BarController,
  Tooltip
);

export default {
  name: "ConversionRatesGraph",
  data() {
    return {
      isPercents: false,
      responseData: [],
      isLoading: false,
      chart: null,
      chartData: {
        labels: [],
        datasets: []
      }
    };
  },
  async mounted() {
    await this.getData()
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods:{
    renderChart() {
      const ctx = this.$refs.conversionRatesGraph.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.chartData,
        options: {
          interaction: {
            mode: 'index',
            intersect: false
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.raw >= 1 ? context.formattedValue : context.raw.toString().replace('.', ',')
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
        },
      });
      this.updateChartData();
      this.chart.update()
    },
    updateChartData() {
      this.chartData.labels = [];
      this.chartData.datasets = [];
      const keys = this.isPercents ? ['basic_pay_pct', 'trial_pay_pct'] : ['basic_pay_count', 'trial_pay_count'];

      keys.forEach((key, index) => {
        const dataset = {
          label: key,
          backgroundColor: index === 0 ? '#45a164' : '#2c3e50',
          borderColor: index === 0 ? '#45a164' : '#2c3e50',
          data: []
        };

        this.responseData.forEach(item => {
          dataset.data.push(item[key]);
          if (!this.chartData.labels.includes(item.date)) {
            this.chartData.labels.push(item.date);
          }
        });

        this.chartData.datasets.push(dataset);
      });
    },
    async getData(){
      try{
        this.isLoading = true
        const res = await axios.get('/api/v2/analytics/user_tariff')
        if(res?.data?.data){
          this.isLoading = false
          this.responseData = res?.data?.data
          this.renderChart();
        }
      }finally{
        this.isLoading = false
      }
    }
  },
  watch: {
    isPercents() {
      if (this.chart) {
        this.updateChartData();
        this.chart.update();
      }
    }
  }
}
</script>



<style lang="scss" scoped>

.card {
  font-size: 0.75rem;
  background: var(--second-card-bg);
  min-height: 200px;
  border: 1px solid var(--scroll-handler) !important;
  margin: 0;
}
.c-dark-theme {
  .card {
    border: none !important;
  }
}
.c-card-header {
  padding: 0.375rem 1.25rem;

  @include media-breakpoint-up(lg) {
    padding: 0.75rem 1.25rem;
  }
}
.loader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
