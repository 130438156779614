var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    {
      staticClass: "shadow-none analytics-card",
      attrs: { color: "second-card-bg" },
    },
    [
      _c("CCardBody", [
        _c(
          "h2",
          {
            staticClass: "analytics-card__title",
            class: _vm.color,
            style: `color: ${_vm.colorHex}`,
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
        _c("div", { staticClass: "analytics-card__count" }, [
          _vm._v(" " + _vm._s(_vm.count) + " "),
          _vm.hasDiff
            ? _c("span", { staticClass: "diff", class: _vm.diffClass }, [
                _vm._v(
                  " (" + _vm._s(_vm.diff > 0 ? "+" + _vm.diff : _vm.diff) + ") "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }