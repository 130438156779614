var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("cumulative-income-graph"),
      _c("income-graph"),
      _c("cumulative-user-reg-graph"),
      _c("user-subscribe-graph"),
      _c("month-ARPU-graph"),
      _c("LTV-graph"),
      _c("active-user-amount-graph"),
      _c("churn-rate-graph"),
      _c("user-time-spent-graph"),
      _c("conversion-rates-graph"),
      _c("user-reg-increase-graph", { staticClass: "mb-4" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }