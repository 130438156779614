<template>
  <CCard class="card shadow-none">
    <CCardHeader class="c-card-header d-flex bg-dark position-relative">
      <h4>
        {{ $t('lk.analytics.cumulativeIncome') }}
      </h4>
    </CCardHeader>
    <CCardBody class="px-0 py-1">
      <div
        v-if="isLoading"
        class="loader"
      >
        <CSpinner
          color="primary"
        />
      </div>
      <div
        v-if="!isLoading && !responseData.length"
        class="loader"
      >
        <h3>
          {{ $t('general.noData') }}
        </h3>
      </div>
      <div
        class="position-relative"
      >
        <canvas ref="cumulativeIncomeChart" width="400" height="300"></canvas>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import {
  Chart,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  Tooltip
} from 'chart.js';
import axios from "axios"
Chart.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  Tooltip
);
export default {
  name: "CumulativeIncomeChart",
  props: {
  },
  data() {
    return {
      responseData: [],
      isLoading: false,
      chart: null,
      chartData: {
        labels: [],
        datasets: [{
          label: 'Value',
          data: [],
          backgroundColor:"#45a164",
          borderColor:"#45a164",
          borderWidth:1,
          pointHoverBackgroundColor:"#45a164"

        }]
      }
    };
  },
  async mounted() {
    await this.getData()
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods:{
    renderChart() {
      const ctx = this.$refs.cumulativeIncomeChart.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'line',
        data: this.chartData,
        options: {
          interaction: {
            mode: 'index',
            intersect: false
          },
          responsive: true,
          elements: {
            line: {
              tension: 0
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
              chartElement: null
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.raw >= 1 ? context.formattedValue : context.raw.toString().replace('.', ',')
                },
              },
            },
          },
        },
      });

      this.responseData.forEach(item => {
        this.chartData.labels.push(item.date);
        this.chartData.datasets[0].data.push(item.value);
      });

      this.chart.update()
    },
    async getData(){
      try{
        this.isLoading = true
        const res = await axios.get('/api/v2/analytics/cumulative_income')
        if(res?.data?.data){
          this.isLoading = false
          this.responseData = res?.data?.data
          this.renderChart();
        }
      }finally{
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.card {
  font-size: 0.75rem;
  background: var(--second-card-bg);
  min-height: 200px;
  border: 1px solid var(--scroll-handler) !important;
  margin: 0;
}
.c-dark-theme {
  .card {
    border: none !important;
  }
}
.c-card-header {
  padding: 0.375rem 1.25rem;

  @include media-breakpoint-up(lg) {
    padding: 0.75rem 1.25rem;
  }
}
.loader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
