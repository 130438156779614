<template>
  <CCard class="card shadow-none">
    <CCardHeader class="c-card-header d-flex bg-dark position-relative">
      <h4>
        {{ $t('lk.analytics.userTimeSpent') }}
      </h4>
    </CCardHeader>
    <CCardBody class="px-0 py-1">
      <div
        v-if="isLoading"
        class="loader"
      >
        <CSpinner
          color="primary"
        />
      </div>
      <div
        v-if="!isLoading && !responseData.length"
        class="loader"
      >
        <h3>
          {{ $t('general.noData') }}
        </h3>
      </div>
      <div
        class="position-relative"
      >
        <canvas ref="userTimeSpentGraph" width="400" height="300"></canvas>
      </div>
    </CCardBody>
  </CCard>
</template>

<!-- <script>
import { Chart, registerables } from 'chart.js';
import axios from "axios";

Chart.register(...registerables);

export default {
  name: "UserTimeSpentGraph",
  data() {
    return {
      responseData: [],
      isLoading: false,
      chart: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const res = await axios.get('/api/v2/analytics/user_online');
        if (res?.data?.data) {
          this.isLoading = false;
          this.responseData = res.data.data;
          this.renderChart();
        }
      } finally {
        this.isLoading = false;
      }
    },
    renderChart() {
      const ctx = this.$refs.userTimeSpentGraph.getContext('2d');

      const labels = this.responseData.map(item => item.date);
      const datasets = [
        { label: 'Pro', data: this.responseData.map(item => item.pro) },
        { label: 'Pro Alerts', data: this.responseData.map(item => item.pro_alerts) },
        { label: 'Basic', data: this.responseData.map(item => item.basic) },
        { label: 'Trial Pro Alerts', data: this.responseData.map(item => item.trial_pro_alerts) }
      ];

      this.chart = new Chart(ctx, {
        type: 'radar',
        data: {
          labels: labels,
          datasets: datasets.map(ds => ({
            label: ds.label,
            backgroundColor: this.getRandomColor(),
            borderColor: this.getRandomColor(),
            pointBackgroundColor: this.getRandomColor(),
            data: ds.data
          }))
        },
        options: {
          scales: {
            radialLinear: {
              suggestedMin: 0
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' + context.raw;
                }
              }
            }
          }
        }
      });
    },
    getRandomColor() {
      return `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.2)`;
    }
  }
};
</script> -->

<script>
import { Chart, registerables } from 'chart.js';
import axios from "axios";

Chart.register(...registerables);

export default {
  name: "UserTimeSpentGraph",
  data() {
    return {
      responseData: [],
      isLoading: false,
      chart: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const res = await axios.get('/api/v2/analytics/user_online');
        if (res?.data?.data) {
          this.isLoading = false;
          this.responseData = res.data.data;
          this.renderChart();
        }
      } finally {
        this.isLoading = false;
      }
    },
    renderChart() {
      const ctx = this.$refs.userTimeSpentGraph.getContext('2d');

      const labels = ['Basic', 'Pro Alerts', 'Pro', 'Trial Pro Alerts'];
      const datasets = this.responseData.map(item => ({
        label: item.date,
        backgroundColor: this.getRandomColor(),
        borderColor: this.getRandomColor(),
        pointBackgroundColor: this.getRandomColor(),
        data: [
          item.basic || 0,
          item.pro_alerts || 0,
          item.pro || 0,
          item.trial_pro_alerts || 0
        ]
      }));

      this.chart = new Chart(ctx, {
        type: 'radar',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          scales: {
            radialLinear: {
              suggestedMin: 0
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return context.dataset.label + ': ' + context.raw;
                }
              }
            }
          }
        }
      });
    },
    getRandomColor() {
      return `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.2)`;
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  font-size: 0.75rem;
  background: var(--second-card-bg);
  min-height: 200px;
  border: 1px solid var(--scroll-handler) !important;
  margin: 0;
}
.c-dark-theme {
  .card {
    border: none !important;
  }
}
.c-card-header {
  padding: 0.375rem 1.25rem;

  @include media-breakpoint-up(lg) {
    padding: 0.75rem 1.25rem;
  }
}
.loader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
