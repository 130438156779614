var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "card shadow-none" },
    [
      _c(
        "CCardHeader",
        { staticClass: "c-card-header d-flex bg-dark position-relative" },
        [
          _c("h4", [
            _vm._v(" " + _vm._s(_vm.$t("lk.analytics.activeUserCount")) + " "),
          ]),
        ]
      ),
      _c("CCardBody", { staticClass: "px-0 py-1" }, [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "loader" },
              [_c("CSpinner", { attrs: { color: "primary" } })],
              1
            )
          : _vm._e(),
        !_vm.isLoading && !_vm.responseData.length
          ? _c("div", { staticClass: "loader" }, [
              _c("h3", [_vm._v(" " + _vm._s(_vm.$t("general.noData")) + " ")]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "position-relative" }, [
          _c("canvas", {
            ref: "activeUserAmountGraph",
            attrs: { width: "400", height: "300" },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }