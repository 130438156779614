<template>
  <CCard class="shadow-none analytics-card" color="second-card-bg">
    <CCardBody>
      <h2
        class="analytics-card__title"
        :class="color"
        :style="`color: ${colorHex}`"
      >
        {{ title }}
      </h2>
      <div class="analytics-card__count">
        {{ count }}
        <span
          v-if="hasDiff"
          class="diff"
          :class="diffClass"
        >
          ({{ diff > 0 ? '+' + diff : diff }})
        </span>
      </div>
    </CCardBody>
  </CCard>
</template>
<script>
export default {
  name: "AnalyticsItem",
  props: {
    title: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: null
    },
    diff: {
      type: Number,
      default: null
    },
    color: {
      type: String,
      default: ''
    },
    colorHex: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasDiff () {
      return (
        typeof this.diff === 'number' &&
        !isNaN(this.diff)
      )
    },
    diffClass () {
      if (this.diff === 0) return 'text-warning'
      return this.diff > 0 ? 'text-success' : 'text-danger'
    }
  }
}
</script>

<style lang="scss">
.analytics-card {
  &__title {
    font-size: 1.2rem;
    color: var(--white);
    text-transform: uppercase;

    &.yellow {
      color: #f8cb00;
    }

    &.green {
      color: #20c997;
    }

    &.pink {
      color: #e83e8c;
    }
    &.blue {
      color: rgb(32, 168, 216);
    }
  }

  &__count {
    font-size: 1.2rem;
    color: var(--analytic-item-count)
  }
}
.diff {
  font-size: 0.7em;
}
</style>
