<template>
  <div class="h-100 d-flex flex-column position-relative">
    <div class="d-flex flex-row align-items-center header">
      <h1 class="title mb-0 mr-3">{{title}}</h1>
      <time-range-selector
          :hideTitle="true"
          :options="tabList"
          v-model="selectedTab"
          @input='tabChange'
          class="tabSelector"
        />
    </div>
    <div
      v-if="loading"
      class="spinner-custom spinner-custom--big"
    >
      <CSpinner
        color="primary"
      />
    </div>
    <template v-if="!loading && analytics.length > 0">
      <template v-if="selectedTab === 1">
        <div class="mb-3" v-for="(analytic, i) in analytics" :key="i">
          <h6>{{ analytic.title }}</h6>
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3" v-for="(item, i) in analytic.items" :key="i">
              <analytics-item
                :title="item.title"
                :count="item.count"
                :color="item.color"
                :colorHex="item.colorHex"
                :diff="item.diff"
              />
            </div>
          </div>
        </div>
      </template>
      <dashboard v-if="selectedTab === 2"/>
    </template>
  </div>
</template>

<script>
import Dashboard from "@/components/analytics/Dashboard";
import TimeRangeSelector from '@/components/training/tabSelector'
import axios from 'axios';
import AnalyticsItem from '@/components/analytics/AnalyticsItem.vue'

export default {
  name: "Analytics",
  components: { AnalyticsItem, TimeRangeSelector, Dashboard },
  data() {
    return {
      loading: false,
      analytics: [],
      selectedTab: +this.$route.query?.activeTab || 1,
    }
  },
  computed: {
    title(){
      return this.tabList.find(item => item.key === this.selectedTab).label
    },
    IS_RU () {
      return this.$i18n.locale === 'ru'
    },
    tabList() {
      return [
        {
          label: this.$t(`header.analytics`),
          key: 1,
          iconName: 'cil-group'
        },
        {
          label: this.$t(`header.dashboard`),
          key: 2,
          iconName: 'cil-lock-locked'
        }
      ]
    }
  },

  created() {
    this.getAnalytics()
  },
  methods: {
    tabChange(tab){
      this.$router.replace({
        name: this.$route.name,
        query:{
          activeTab: tab
        }
      })
    },
    getAnalytics () {
      this.loading = true
      axios.get('/api/v1/stat/users')
        .then(res => {
          this.analytics = this.parseAnalyticsData(res?.data?.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    parseAnalyticsData (data) {
      const getDiff = (a, b) => {
        if (
          typeof a === 'number' &&
          !isNaN(a) &&
          typeof b === 'number' &&
          !isNaN(b)
        ) {
          return a - b
        } else {
          return null
        }
      }

      return [
        {
          title: this.IS_RU ? 'Количество пользователей' : 'Count users',
          items: [
            {
              title: this.IS_RU ? 'Всего' : 'All',
              count: data?.count_all
            },
            {
              title: this.IS_RU ? 'Онлайн' : 'Online',
              count: data?.count_online
            },
            {
              title: this.IS_RU ? 'Гости' : 'Guests',
              count: data?.count_guest_online
            }
          ]
        },
        {
          title: this.IS_RU ? 'Статистика обучения' : 'Training statistics',
          items: [
            {
              title: this.IS_RU ? 'Кураторы' : 'Сurators',
              count: data?.count_curator
            },
            {
              title: this.IS_RU ? 'Ученики' : 'Students',
              count: [
                ...Object.values(data?.count_users_standart || {}),
                ...Object.values(data?.count_users_allIn || {}),
                ...Object.values(data?.count_users_web3 || {}),
                ...Object.values(data?.count_users_crypto_basics || {})
              ].reduce(function(a, b) { return a + b; }, 0),
            }
          ]
        },
        {
          title: '',
          items: [
            {
              title: this.IS_RU ? 'STANDARD' : 'STANDARD',
              count: Object.values(data?.count_users_standart || {}).reduce(function(a, b) { return a + b; }, 0),
              colorHex: '#3686F6'
            },
            {
              title: this.IS_RU ? 'ALL IN' : 'ALL IN',
              count: Object.values(data?.count_users_allIn || {}).reduce(function(a, b) { return a + b; }, 0),
              colorHex: '#FCB81C'
            },
            {
              title: this.IS_RU ? 'WEB3' : 'WEB3',
              count: Object.values(data?.count_users_web3 || {}).reduce(function(a, b) { return a + b; }, 0),
              colorHex: '#F66936'
            },
            {
              title: this.IS_RU ? 'Crypto Basics' : 'Crypto Basics',
              count: Object.values(data?.count_users_crypto_basics || {}).reduce(function(a, b) { return a + b; }, 0),
              colorHex: '#F66936'
            }
          ]
        },
        {
          title: this.IS_RU ? 'Количество действующих тарифов' : 'Count tariffs',
          items: [
            {
              title: 'Trial',
              count: data?.count_trial
            },
            // {
            //   title: 'Delta',
            //   count: data?.count_delta,
            //   color: 'green'
            // },
            // {
            //   title: 'Screener',
            //   count: data?.count_screener,
            //   color: 'yellow'
            // },
            {
              title: 'Basic',
              count: data?.count_basic,
              color: 'pink'
            },
            {
              title: 'Pro',
              count: data?.count_pro,
              color: 'blue'
            },
            {
              title: 'Pro + ALERTS',
              count: data?.count_pro_alerts,
              colorHex: '#FCB81C'
            },
          ]
        },
        {
          title: this.IS_RU ? 'Количество регистраций' : 'Count registers',
          items: [
            {
              title: this.IS_RU ? 'День' : 'Day',
              count: data?.count_day,
              diff: getDiff(data?.count_day, data?.count_last_day)
            },
            {
              title: this.IS_RU ? 'Неделя' : 'Week',
              count: data?.count_week,
              diff: getDiff(data?.count_week, data?.count_last_week)
            },
            {
              title: this.IS_RU ? 'Месяц': 'Month',
              count: data?.count_month,
              diff: getDiff(data?.count_month, data?.count_last_month)
            }
          ]
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .spinner-custom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

     &--big {
       & .spinner-border {
        width: 4rem;
        height: 4rem;
       }
     }
  }

  .header{
    @include media-breakpoint-up(sm) {
      margin-bottom: 1.75rem;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 2.25rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2.25rem;
    }
  }

  .title {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
      font-size: 1.25rem;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 2.25rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2.25rem;
    }

  }

  .tabSelector {
    overflow: initial !important;
    /deep/ .btn-group {
      flex-direction: row;
    }

    /deep/ .button-custom {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 18px;
      column-gap: 5px;
    }
  }
</style>
