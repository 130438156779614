var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 d-flex flex-column position-relative" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row align-items-center header" },
        [
          _c("h1", { staticClass: "title mb-0 mr-3" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("time-range-selector", {
            staticClass: "tabSelector",
            attrs: { hideTitle: true, options: _vm.tabList },
            on: { input: _vm.tabChange },
            model: {
              value: _vm.selectedTab,
              callback: function ($$v) {
                _vm.selectedTab = $$v
              },
              expression: "selectedTab",
            },
          }),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "spinner-custom spinner-custom--big" },
            [_c("CSpinner", { attrs: { color: "primary" } })],
            1
          )
        : _vm._e(),
      !_vm.loading && _vm.analytics.length > 0
        ? [
            _vm.selectedTab === 1
              ? _vm._l(_vm.analytics, function (analytic, i) {
                  return _c("div", { key: i, staticClass: "mb-3" }, [
                    _c("h6", [_vm._v(_vm._s(analytic.title))]),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(analytic.items, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "col-sm-6 col-md-4 col-lg-3" },
                          [
                            _c("analytics-item", {
                              attrs: {
                                title: item.title,
                                count: item.count,
                                color: item.color,
                                colorHex: item.colorHex,
                                diff: item.diff,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                })
              : _vm._e(),
            _vm.selectedTab === 2 ? _c("dashboard") : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }